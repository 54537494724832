import { useTranslation } from "react-i18next";
import { showToast } from "../config/helpers";
import { Request } from "../config/requests";
import { getToken } from "../config/token";
import { useCallback } from "react";

export function useGetPets() {
  const { t } = useTranslation();

  const getPets = useCallback(async () => {
    const token = getToken();
    try {
      const res = await Request({
        method: "get",
        token: token,
        route: `api/users/v2/pets`,
      });
      const json = await res.json();
      return {
        data: json.data,
        error: null,
      }
    } catch (error) {
      showToast(t("somethingWentWrong"), { error: true });
      console.error(error);

      return {
        data: null,
        pets: null,
        error: true,
      }
    }
  }, [])

  return getPets;
}