import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../../components/modalWrapper";
import CatDog from "../../../assets/Insurances/cat_dog.svg";
import { updateSelectedPetInfo } from "../../../config/actions";
import Loading from "../../../components/common/Loading";

function ModalPetSelect({
  openModalPetSelect,
  setOpenModalPetSelect,
  setOpenModalWhatToDo,
  setPetId,
}) {
  const { t } = useTranslation();
  const pets = useSelector((s) => s.flow.pets);
  const [selectedPet, setSelectedPet] = useState(null);

  function handleClose() {
    setSelectedPet(null);
    setOpenModalPetSelect(false);
  }

  const RenderPets = () => {
    const rows = [];
    const numPets = pets.length;
    const dispatch = useDispatch();

    for (let i = 0; i < numPets; i += 3) {
      const rowPets = pets.slice(i, i + 3);
      const row = (
        <div key={`row-${i}`} className="pet_container__row-modal">
          {rowPets.map((pet, ind) => (
            <div
              key={ind}
              className={`pet_container__item ${pet.policy.id !== selectedPet ? "not-selected" : ""}`}
              onMouseOver={() => setSelectedPet(pet?.policy.id)}
              onClick={() => {
                setPetId(pet?.policy.id);
                dispatch(updateSelectedPetInfo({
                  ...pet.attributes,
                  type: pet.attributes.name,
                  policyId: pet.policy.id,
                }));
                handleClose();
                setOpenModalWhatToDo(true);
              }}
            >
              <div className="pet_container__wrapper-modal">
                <div>
                  {pet?.image ? (
                    <img
                      src={`data:image/jpeg;base64, ${pet?.image}`}
                      alt="CatDog"
                      height="100%"
                    />
                  ) : (
                    <img src={CatDog} alt="CatDog" width="95%" height="95%" />
                  )}
                </div>
              </div>
              <div className="pet_container__name">
                {pet?.attributes?.nameOfPet}
                {pets.filter(el => el.attributes.nameOfPet === pet.attributes.nameOfPet).length > 1 &&
                  <div>{pet?.policy.policyNo}</div>}
              </div>
            </div>
          ))}
        </div>
      );
      rows.push(row);
    }
    return (pets.length > 0 ? rows : <Loading noText fast />);
  };

  return (
    <ModalWrapper
      headerTitle={t("submitInvoice")}
      openModal={openModalPetSelect}
      setModalWrapper={() => handleClose()}
    >
      <>
        <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
          {t("whichPetContinue")}
        </div>
        <div className="modal_container__body__inputs" style={{ rowGap: "5px" }}>
          {RenderPets()}
        </div>
      </>
    </ModalWrapper>
  );
}

export default ModalPetSelect;
