export const MISSING_OCR_TYPES = {
  "diagnosis": "diagnosis",
  "chipnumberDoesNotMatch": "chipnumber does not match",
  "chipnumberNotFound": "chipnumber not found",
  "duplicates": "duplicates",
}

export const FLOW_TYPES = {
  'General': 'General',
  'General to Vets': 'General to Vets',
  'Existing Claim': 'Existing Claim',
  'Cost estimation': 'Cost estimation',
  'Liability claim': 'Liability claim'
}

export const LAST_CLAIM_ERROR_MSG = "Last claim check error"