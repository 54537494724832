import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import { showToast } from "../../../../config/helpers";
import ModalWrapper from "../../../../components/modalWrapper";
import Attach from "../../../../assets/iconsSmall/paper_clip.svg";
import Remove from "../../../../assets/iconsSmall/cross_black.svg";
import Upload from "../../../../assets/iconsSmall/upload_icon.svg";
import { Request } from "../../../../config/requests";
import { Markup } from "interweave";
import CustomSelect from "../../../../components/inputs/select/CustomSelect";
import Loading from "../../../../components/common/Loading";
import { useNavigate } from "react-router-dom";
import Datepicker from "../../../../components/common/components/Datepicker";
import breedListDogs from "../../../../assets/dog_breeds.json";
import breedListCats from "../../../../assets/cat_breeds.json";
import moment from "moment";

function ModalPetData({
  openModalPetData,
  setOpenModalPetData,
  initialPetType,
  petData
}) {
  const { t } = useTranslation();
  const router = useNavigate();
  const [dialog, setDialog] = useState("main");
  const [dataType, setDataType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [breedOptions, setBreedOptions] = useState([]);
  const [chipError, setChipError] = useState(false);
  const [petType, setPetType] = useState("");
  const [petName, setPetName] = useState("");
  const [petBreedType, setPetBreedType] = useState("");
  const [petBreed, setPetBreed] = useState("");
  const [petFirstBreed, setPetFirstBreed] = useState("");
  const [petSecondBreed, setPetSecondBreed] = useState("");
  const [petBD, setPetBD] = useState("");
  const [petChip, setPetChip] = useState("");
  const [petGender, setPetGender] = useState("");
  const [dogHeight, setDogHeight] = useState("");
  const [catHabitat, setCatHabitat] = useState("");

  const headerLabels = {
    "main": t("changeData"),
    "Pet name": t("petNameChange"),
    "Pet breed": t("petBreedChange"),
    "Pet birthday": t("petBDChange"),
    "Pet chipnumber": t("petChipChange"),
    "Pet gender": t("petGenderChange"),
    "Dog height": t("dogHeightChange"),
    "Cat habitat": t("catHabitatChange"),
  };

  const changeDataTypesDog = [
    { label: t("Pet name"), value: "Pet name" },
    { label: t("Pet breed"), value: "Pet breed" },
    { label: t("Pet birthday"), value: "Pet birthday" },
    { label: t("Pet chipnumber"), value: "Pet chipnumber" },
    { label: t("Pet gender"), value: "Pet gender" },
    { label: t("Dog height"), value: "Dog height" },
  ];

  const changeDataTypesCat = [
    { label: t("Pet name"), value: "Pet name" },
    { label: t("Pet breed"), value: "Pet breed" },
    { label: t("Pet birthday"), value: "Pet birthday" },
    { label: t("Pet chipnumber"), value: "Pet chipnumber" },
    { label: t("Pet gender"), value: "Pet gender" },
    { label: t("Cat habitat"), value: "Cat habitat" },
  ];

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handlePetType = (e) => {
    setPetType(e.target.value);
    setPetBreed("");
    setPetFirstBreed("");
    setPetSecondBreed("");
  };

  const isValidNumber = (str) => {
    return /^[0-9]{15}$/.test(str);
  };

  const handleChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setPetChip(value);
        setChipError(false);
      } else {
        setPetChip(value);
        setChipError(true);
      }
    }
  };

  function handleResetValues() {
    setPetBreed("");
    setPetFirstBreed("");
    setPetSecondBreed("");
    setDogHeight("");
  }

  function handleClose() {
    setDataType(null);
    setSelectedFiles([]);
    setDialog("main");
    setOpenModalPetData(false);
  }

  async function handleSubmit() {
    setSubmitting(true);
    setDialog("thankYouYes");
    const fd = new FormData();
    selectedFiles.forEach(i => fd.append('files', i))
    fd.append('reportType', "Change pet data");
    fd.append('customerEmail', petData?.quote?.customer?.email);
    fd.append('policyNo', petData?.policy?.policyNo);
    fd.append('policyId', petData?.quote?.policy?.id);
    fd.append('petType', petType);
    fd.append('breedType', petBreedType);
    fd.append('breed', petBreed?.label || "");
    fd.append('hybridBreed1', petFirstBreed?.label || "");
    fd.append('hybridBreed2', petSecondBreed?.label || "");
    fd.append('nameOfPet', petName);
    fd.append('dateOfBirth', petBD ? moment(petBD).format("DD.MM.YYYY") : "");
    fd.append('chipNumber', petChip);
    fd.append('petGender', petGender);
    fd.append('sizeOfPet', dogHeight);
    fd.append('catType', catHabitat);

    return Request({
      method: "post",
      route: "api/users/change_data_request",
      formData: fd,
    })
      .then(res => {
        if (!res.ok) throw new Error(`Request failed with status ${res.status}`);
      })
      .catch(() => {
        showToast(t("somethingWentWrong"), { error: true });
        router("/")
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  useEffect(() => {
    if (dialog === "Pet breed") {
      initialPetType === "DOG"
        ? setPetType("Dog")
        : setPetType("Cat")
    }
  }, [dialog]);

  useEffect(() => {
    let options = [];
    let breedList = petType === "Dog" ? breedListDogs : breedListCats;

    breedList.forEach((el) => {
      options.push({ label: el.value_name, value: el.value });
    });
    if (petBreedType === "Pure") options.shift();

    if (petFirstBreed) {
      options = options.filter(breed => breed.value !== petFirstBreed.value)
    }
    if (petSecondBreed) {
      options = options.filter(breed => breed.value !== petSecondBreed.value)
    }
    setBreedOptions(options);
  }, [petType, petBreedType, petFirstBreed, petSecondBreed]);

  return (
    <ModalWrapper
      headerTitle={headerLabels[dataType?.value || dialog]}
      openModal={openModalPetData}
      setModalWrapper={() => handleClose()}
    >
      {dialog === "main" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset", margin: "0" }}>
            {t("whichPetDataToChange")}
          </div>
          <div className="modal_container__body__inputs" style={{ margin: "20px 0" }}>
            <CustomSelect
              variant="modal-cancel-contract"
              options={initialPetType === "DOG" ? changeDataTypesDog : changeDataTypesCat}
              formatCreateLabel={(i) => i}
              placeholder={t("pleaseSelectHere")}
              value={dataType}
              onChange={(e) => setDataType(e)}
              isClearable={true}
            />
          </div>
          <div
            className={
              (dataType !== null)
                ? `button_red_small ${isMobileOnly && "mobile"}`
                : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
            }
            onClick={(dataType !== null)
              ? () => setDialog(dataType.value)
              : ((e) => e.stopPropagation())}
          >
            {t("Next")}
          </div>
        </>}

      {dialog === "Pet name" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseEnterPetName")}
          </div>
          <div className="modal_container__body__inputs">
            <input
              className="modal_container__body__input"
              placeholder={t("name")}
              value={petName}
              onChange={(e) => setPetName(e.target.value)}
            />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petName ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petName
                ? (() => handleSubmit())
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet breed" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectPetType")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="Dog"
                  checked={petType === 'Dog'}
                  onChange={(e) => handlePetType(e)}
                  style={{ marginRight: '8px' }}
                />
                {t("dog")}
              </label>
            </div>
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="Cat"
                  checked={petType === 'Cat'}
                  onChange={(e) => handlePetType(e)}
                  style={{ marginRight: '8px' }}
                />
                {t("cat")}
              </label>
            </div>
          </div>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectBreedType")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="Pure"
                  checked={petBreedType === 'Pure'}
                  onChange={(e) => {
                    handleResetValues();
                    setPetBreedType(e.target.value);
                  }}
                  style={{ marginRight: '8px' }}
                />
                {t("pureBreed")}
              </label>
            </div>
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="Hybrid"
                  checked={petBreedType === 'Hybrid'}
                  onChange={(e) => {
                    handleResetValues();
                    setPetBreedType(e.target.value);
                  }}
                  style={{ marginRight: '8px' }}
                />
                {t("hybridBeed")}
              </label>
            </div>
            <div className="modal_container__body__radio">
              <label style={{ display: 'block', marginBottom: '8px' }}>
                <input
                  type="radio"
                  value="Unknown"
                  checked={petBreedType === 'Unknown'}
                  onChange={(e) => {
                    handleResetValues();
                    setPetBreedType(e.target.value);
                  }}
                  style={{ marginRight: '8px' }}
                />
                {t("unknownBeed")}
              </label>
            </div>
            <div className="modal_container__body__inputs" style={{ margin: "20px 0" }}>
              {petBreedType === "Pure" &&
                <>
                  <CustomSelect
                    variant="modal-cancel-contract"
                    options={breedOptions}
                    formatCreateLabel={(i) => i}
                    placeholder={t("pleaseSelectHere")}
                    value={petBreed}
                    onChange={(e) => setPetBreed(e)}
                    isClearable={true}
                    toTranslate={false}
                  />
                </>}
              {petBreedType === "Hybrid" &&
                <>
                  <CustomSelect
                    variant="modal-edit-data"
                    options={breedOptions}
                    formatCreateLabel={(i) => i}
                    placeholder={t("pleaseSelectHere")}
                    value={petFirstBreed}
                    onChange={(e) => setPetFirstBreed(e)}
                    isClearable={true}
                    toTranslate={false}
                  />
                  <CustomSelect
                    variant="modal-cancel-contract"
                    options={breedOptions}
                    formatCreateLabel={(i) => i}
                    placeholder={t("pleaseSelectHere")}
                    value={petSecondBreed}
                    onChange={(e) => setPetSecondBreed(e)}
                    isClearable={true}
                    toTranslate={false}
                  />
                </>}
              {petType === "Dog" && petBreedType === "Unknown" &&
                <div className="modal_container__body__row-modal">
                  <div
                    className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${dogHeight === "0-44cm" ? "" : "disabled"}`}
                    onClick={() => setDogHeight("0-44cm")}
                  >
                    0 - 44 cm
                  </div>
                  <div
                    className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${dogHeight === "> 44cm" ? "" : "disabled"}`}
                    onClick={() => setDogHeight("> 44cm")}
                  >
                    &gt; 44 cm
                  </div>
                </div>}
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${(petBreed || (petFirstBreed && petSecondBreed) || dogHeight || (petType === "Cat" && petBreedType === "Unknown")) ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={
                (petBreed || (petFirstBreed && petSecondBreed) || dogHeight || (petType === "Cat" && petBreedType === "Unknown"))
                  ? () => setDialog("petBreedConfirm")
                  : ((e) => e.stopPropagation())
              }>
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "petBreedConfirm" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("changeBreedMessage")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => handleSubmit()}
            >
              {t("yes")}
            </div>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouNo")}
            >
              {t("no")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet chipnumber" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseEnterPetChip")}
          </div>
          <div className="modal_container__body__inputs">
            <input
              className="modal_container__body__input"
              placeholder={t("chipHere")}
              value={petChip}
              onChange={handleChip}
              style={{
                border: chipError ? "4px solid var(--b-color-main-2)" : "",
              }}
            />
            {chipError &&
              <div className="modal_container__body__error FastShow">
                {t("chipWrong")}
              </div>}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petChip ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petChip
                ? (() => setDialog("attachFiles"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet birthday" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseEnterPetBD")}
          </div>
          <div className="modal_container__body__dropdowns">
            <Datepicker
              date={petBD}
              setDate={(newValue) => setPetBD(newValue)}
              disabled={false}
              type={"reset-dp-modal"}
            />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petBD ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petBD
                ? (() => setDialog("attachFiles"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "petBDConfirm" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("changeBDMessage")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => handleSubmit()}
            >
              {t("yes")}
            </div>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouNo")}
            >
              {t("no")}
            </div>
          </div>
        </>
      }

      {dialog === "Pet gender" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectPetGender")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__row-modal">
              <div
                className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${petGender === "Male" ? "" : "disabled"}`}
                onClick={() => setPetGender("Male")}
              >
                {t("male")}
              </div>
              <div
                className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${petGender === "Female" ? "" : "disabled"}`}
                onClick={() => setPetGender("Female")}
              >
                {t("female")}
              </div>
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${petGender ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={petGender
                ? (() => handleSubmit())
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "Dog height" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectDogHeight")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__row-modal">
              <div
                className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${dogHeight === "0-44cm" ? "" : "disabled"}`}
                onClick={() => setDogHeight("0-44cm")}
              >
                0 - 44 cm
              </div>
              <div
                className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${dogHeight === "> 44cm" ? "" : "disabled"}`}
                onClick={() => setDogHeight("> 44cm")}
              >
                &gt; 44 cm
              </div>
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${dogHeight ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={dogHeight
                ? (() => setDialog("dogHeightConfirm"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "dogHeightConfirm" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("changeHeightMessage")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => handleSubmit()}
            >
              {t("yes")}
            </div>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouNo")}
            >
              {t("no")}
            </div>
          </div>
        </>
      }

      {dialog === "Cat habitat" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("pleaseSelectCatHabitat")}
          </div>
          <div className="modal_container__body__inputs">
            <div className="modal_container__body__row-modal">
              <div
                className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${catHabitat === "Indoor" ? "" : "disabled"}`}
                onClick={() => setCatHabitat("Indoor")}
              >
                {t("Indoor")}
              </div>
              <div
                className={`modal_container__body__button-modal ${isMobile ? "wide" : ""} ${catHabitat === "Outdoor" ? "" : "disabled"}`}
                onClick={() => setCatHabitat("Outdoor")}
              >
                {t("Outdoor")}
              </div>
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""} ${catHabitat ? "" : "disabled"}`}
              style={{ width: "unset" }}
              onClick={catHabitat
                ? (() => setDialog("catHabitatConfirm"))
                : ((e) => e.stopPropagation())}
            >
              {t("sendChange")}
            </div>
          </div>
        </>
      }

      {dialog === "catHabitatConfirm" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("changeHabitatMessage")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => handleSubmit()}
            >
              {t("yes")}
            </div>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => setDialog("thankYouNo")}
            >
              {t("no")}
            </div>
          </div>
        </>
      }

      {dialog === "attachFiles" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("pleaseAttach")}
          </div>
          <div
            className="modal_container__body__uploader"
            style={{ maxWidth: "540px" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              // accept=".jpg,.png"
              onChange={handleFileChange}
              hidden
              multiple
            />
            <img src={Upload} />
            <span>{t("docUploadPlaceholder")}</span>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              style={{ width: "unset", padding: isMobile ? "0 20px" : "0 39px" }}
            >
              {t("uploadDocument")}
            </div>
          </div>
          {selectedFiles.length
            ? <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 15px 0" }}>
              {t("uploadedDocuments")}
            </div>
            : null}
          {selectedFiles.length
            ? selectedFiles.map((file, ind) => (
              <div key={ind} className="modal_container__body__attach" style={{ maxWidth: "440px", margin: "0 0 20px 0" }}>
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t("addDocument")}
                  value={file.name || ""}
                />
                <img
                  src={Remove}
                  alt="Remove"
                  style={{
                    position: "absolute",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter(el => el.name !== file.name))}
                />
              </div>
            ))
            : null}
          <div className="modal_container__body__row-modal" style={{ margin: "10px 0 0 0" }}>
            <div
              className={
                (selectedFiles.length)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedFiles.length)
                ? (() => {
                  dataType.value === "Pet birthday"
                    ? setDialog("petBDConfirm")
                    : handleSubmit()
                })
                : ((e) => e.stopPropagation())}
            >
              {t("Next")}
            </div>
          </div>
        </>}

      {dialog === "thankYouYes" &&
        <>
          {submitting
            ? <Loading noText fast scroll={false} />
            : <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 30px 0",
                  textAlign: "start"
                }}
              >
                <Markup content={t("thankYouYesMessage")} />
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  OK
                </div>
              </div>
            </>}
        </>
      }

      {dialog === "thankYouNo" &&
        <>
          <div
            className="modal_container__body__text"
            style={{
              maxWidth: "540px",
              margin: "0 0 30px 0",
              textAlign: "start"
            }}
          >
            <Markup content={t("thankYouNoMessage")} />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </div>
          </div>
        </>
      }

    </ModalWrapper>
  );
}

export default ModalPetData;
