import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import { updateFlowCurrentStep, updateWtdModalOpen, updateUser } from "../../config/actions";
import { isMobile, isMobileOnly } from 'react-device-detect';
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import PawUI from "../../components/ui/PawUI";
import Skeleton from '@mui/material/Skeleton';
import CustomTooltip from "../../components/ui/CustomTooltip";
import CatDog from "../../assets/Insurances/upload_image.svg";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed.svg";
import PandaHeadBothHands from "../../assets/header/panda-logo-both-hands.svg";
import { formatAnyDate, showToast } from "../../config/helpers";
import Loading from "../../components/common/Loading";
import RedDownload from "../../assets/iconsSmall/red_download.svg";
import RedCheck from "../../assets/iconsSmall/red_check.svg";
import BlackCross from "../../assets/PetPage/black_cross.svg";
import ArrowUp from "../../assets/PetPage/arrow_up_small.svg";
import { Markup } from "interweave";
import PandaHead from "../../assets/UserInfo/panda-logo-main.svg";
import CheckDetails from "./components/CheckDetails";
import CrossDetails from "./components/CrossDetails";
import DocsDetails from "./components/DocsDetails";
import { COVERAGES } from "../../constants/ApplicationConstants";
import { chartsExcel } from "./components/ChartsExcel";
import { loadStripe } from '@stripe/stripe-js';
import ModalStripe from "../claims/modals/ModalStripe";
import ErrorModal from "../../components/common/errorModal";
import ModalPetData from "../claims/modals/ModalPetData/ModalPetData";

export default function PetPage() {
  console.log('pet page testing');
  const token = getToken();
  const { t } = useTranslation();
  const history = useNavigate();
  const { id } = useParams();
  const buttonRef = useRef(null);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.form.user);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [userPet, setUserPet] = useState(null);
  const [docHistory, setDocHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [openModalStripe, setOpenModalStripe] = useState(false);
  const [openModalPetData, setOpenModalPetData] = useState(false);
  const [isLeft, setIsLeft] = useState(true);
  const [downloadingPolicyInd, setDownloadingPolicyInd] = useState(null);
  const [downloadingDocInd, setDownloadingDocInd] = useState(null);
  const [downloadingVersion, setDownloadingVersion] = useState(null);
  const [isCheckOpen3, setIsCheckOpen3] = useState(false);
  const [isCheckOpen12, setIsCheckOpen] = useState(false);
  const [isCheckOpen18, setIsCheckOpen18] = useState(false);
  const [showSpecialWaiting, setShowSpecialWaiting] = useState(true);
  const [isCrossOpen, setIsCrossOpen] = useState(false);
  const [error, setError] = useState(false)
  const [paymentFrequency, setPaymentFrequency] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [petType, setPetType] = useState("DOG");
  const [quoteId, setQuoteId] = useState(null);
  const [insuredItemId, setInsuredItemId] = useState(null);
  const [chipNumber, setChipNumber] = useState("");
  const [chipError, setChipError] = useState(false);
  const [petDataUpdating, setPetDataUpdating] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);

  const handleClick = () => {
    dispatch(updateWtdModalOpen(true));

    buttonRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  async function getCardDetails() {
    return Request({
      method: "get",
      token: token,
      route: `api/payment/payment_details?policyId=${id}`,
    })
      .then((res) => {
        if (!res.ok) {
          showToast(t("somethingWentWrong"), { error: true });
        }
        return res.json();
      })
      .then((json) => {
        dispatch(updateUser({ ...userInfo, card4: json.last4, type: json.type, brand: json.brand, additionalInfo: json.additionalInfo }));
        if (!json.last4) {
          setHasSubscription(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        showToast(t("somethingWentWrong"), { error: true });
      })
  }

  async function getPetInfo() {
    return Request({ method: "get", token: token, route: `api/users/v2/pet?policy_id=${id}` })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        setUserPet(json);
        setSelectedPackage(json?.products?.petInsurance?.data?.selectedPackage);
        setPetType(String(json?.data?.name).toLocaleUpperCase());
        setQuoteId(json?.quote?.quote?.id);
        setInsuredItemId(json?.quote?.objects?.insuredItem[0].id);
        switch (json.quote.schedules.payMonthFrequency) {
          default:
          case 1:
            setPaymentFrequency("month");
            break;
          case 3:
            setPaymentFrequency("quarter");
            break;
          case 6:
            setPaymentFrequency("halfYear");
            break;
          case 12:
            setPaymentFrequency("year");
            break;
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        showToast(t("somethingWentWrong"), { error: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getDocHistory() {
    return Request({ method: "get", token: token, route: `api/v2/policy_versions/${id}/documents` })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        const fetchedDocsHistory = json.data;
        const aggregatedDocs = fetchedDocsHistory.reduce((acc, current) => {
          const existing = acc.find(item => item.version === current.version);
          if (existing) {
            existing.documents = [...current.documents];
          } else {
            acc.push({ ...current });
          }

          return acc;
        }, []);

        const latestDocsHistory = [...aggregatedDocs].sort((a, b) => parseInt(a.version) - parseInt(b.version)).pop();
        const includeAlwaysKeyword = [
          "Änderungs-Nachtrag",
          "Mahnung",
          "Abbuchung",
          "Rücktritt",
          "Kündigung",
          "Kündigungsbestätigung",
          "Vertragsbeendigung",
          "Beitragsanpassung",
          "Beitragsrechnung",
        ].map(item => item.toLowerCase().normalize("NFD"));
        const latestVersionKeywords = [
          "Versicherungsschein",
          "Vertragsinformationen",
        ].map(item => item.toLowerCase().normalize("NFD"));

        const filterDocuments = (documents, isLatest) => {
          return documents.filter(doc => {
            const filename = doc.file.filename.normalize("NFD").toLowerCase();

            // Check if the filename contains any of the `includeAlwaysKeyword`
            const containsIncludeAlwaysKeyword = includeAlwaysKeyword.some(keyword => filename.includes(keyword));
            if (containsIncludeAlwaysKeyword) {
              return true;
            }

            // If this is the latest version, check for `latestVersionKeywords`
            if (isLatest) {
              return latestVersionKeywords.some(keyword => filename.includes(keyword));
            }

            return false;
          });
        };

        const filteredDocsHistory = aggregatedDocs.map(docHistory => {
          const isLatest = docHistory.version === latestDocsHistory.version;
          const filteredDocuments = filterDocuments(docHistory.documents, isLatest);
          return { ...docHistory, documents: filteredDocuments };
        }).filter(docHistory => docHistory.documents.length > 0);

        setDocHistory(filteredDocsHistory);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        showToast(t("somethingWentWrong"), { error: true });
      });
  }

  // temporary unused docs archive download

  async function downloadDocs(version = null) {
    setIsDownloading(true);

    try {
      let mainUrl = `api/policy/documents/${id}`;
      if (version) {
        const parsedVersion = parseFloat(version);
        mainUrl += `?version=${parsedVersion + 1}`;
      } else {
        mainUrl += `?version=${1}`;
      }
      const res = await Request({ method: "get", token: token, route: mainUrl, responseType: "blob" });
      if (!res.ok) {
        throw new Error(`Request failed with status ${res.status}`);
      }
      const contentDisposition = res.headers.get("content-disposition");
      const filename = extractFilenameFromContentDisposition(contentDisposition) || "file";
      const blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.click();
      URL.revokeObjectURL(url);
      setIsDownloading(false);
    } catch (error) {
      setError(true);
      console.error(error);
      showToast(t("somethingWentWrong"), { error: true });
    }
  }

  async function downloadDocumentHandler(doc, docInd, policyInd) {
    setDownloadingDocInd(docInd);
    setDownloadingPolicyInd(policyInd);

    try {
      const res = await Request({
        method: "get",
        token: token,
        route: `api/v2/download_policy_doc/${doc.file.documentId}?filename=${doc.file.filename}&mime=${doc.file.mime}&policyId=${id}`,
        responseType: "blob"
      });

      if (!res.ok) {
        throw new Error(`Request failed with status ${res.status}`);
      }
      const contentDisposition = res.headers.get("content-disposition");
      const filename = extractFilenameFromContentDisposition(contentDisposition) || "file";
      const blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      setError(true);
      showToast(t("somethingWentWrong"), { error: true });
      console.error(true);
    } finally {
      setDownloadingDocInd(null);
      setDownloadingPolicyInd(null);
    }
  }

  function extractFilenameFromContentDisposition(contentDisposition) {
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, "");
      }
    }
    return null;
  }

  const onSelectFile = () => {
    ref.current.click();
  };

  const expandDocumentHandler = (targetInd) => {
    let touchedDocs = [...docHistory];
    touchedDocs[targetInd].expanded
      ? touchedDocs[targetInd].expanded = false
      : touchedDocs[targetInd].expanded = true;
    setDocHistory(touchedDocs);
  };

  const postFile = (selectedFile) => {
    setIsLoading(true);
    if (!selectedFile) return Promise.reject("No file selected")
    const fd = new FormData();
    fd.append("file", selectedFile);
    // logic below used for images syncronization when pet has few policies
    // fd.append("similar", userPet.related_products.map((r) => r.id));
    fd.append("similar", []);

    return Request({
      method: "post",
      route: `api/users/pet_image/${userPet.id}`,
      token,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      formData: fd,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .catch((error) => {
        setError(true)
        console.error(error);
        showToast(t("somethingWentWrong"), { error: true });
      })
      .finally(() => {
        getPetInfo();
      });
  };

  const onFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // setFile(URL.createObjectURL(selectedFile));
      postFile(selectedFile);
    }
  };

  const updatePetChip = () => {
    setPetDataUpdating(true);
    Request({
      method: "put",
      token: token,
      route: `api/pet/${id}`,
      values: {
        chipNumber: chipNumber,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        if (json.issue.status === "success") setUserPet({ ...userPet, data: { ...userPet.data, chipNumber: chipNumber } });
        showToast(t("dataChangesConfirmed"));
      })
      .catch((error) => {
        setError(true)
        console.error(error);
        showToast(t("somethingWentWrong"), { error: true });
      })
      .finally(() => {
        setPetDataUpdating(false);
      });
  };

  const openWidgetSafely = () => {
    // Check every 500 milliseconds if the widget is ready
    const intervalId = setInterval(() => {
      if (window.fcWidget && typeof window.fcWidget.open === 'function') {
        clearInterval(intervalId);
        if (window.fcWidget.isOpen()) {
          window.fcWidget.close();
        } else {
          window.fcWidget.open();
        }
      }
    }, 500);
  };

  function isValidNumber(str) {
    return /^[0-9]{15}$/.test(str);
  }

  const handleChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setChipNumber(value);
        setChipError(false);
      } else {
        setChipNumber(value);
        setChipError(true);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(updateFlowCurrentStep(0));
    getPetInfo();
    getDocHistory();
  }, []);

  useEffect(() => {
    if (quoteId && insuredItemId) {
      getCardDetails();
    }
  }, [quoteId, insuredItemId]);

  useEffect(() => {
    selectedPackage === "Healt Basic"
    setShowSpecialWaiting(selectedPackage !== "Health Basic");
  }, [selectedPackage]);

  return (
    <div className="main-container SlowShow">
      <Header getPetInfo={getPetInfo} />
      <div className="paws_layer relative">
        <div className="insurances_container">
          {isMobile && (
            <div
              className="button_red_mobile back"
              onClick={() => history("/")}
            >
              <img src={ArrowLeft} />
              {t("back")}
            </div>
          )}
          {userInfo
            ? (
              <div className="welcome_text">
                {t("hallo")},
                <br />
                <span>
                  {userInfo.title ? `${userInfo.title} ` : ""}{userInfo.firstName} {userInfo.name}
                </span>
              </div>
            )
            : (
              <div className="welcome_text">
                <Skeleton variant="rounded" width={180} height={50} />
              </div>
            )}
          <div className={isMobile ? "pet_main mobile" : "pet_main"}>
            {!isMobile &&
              <div className="arrow_back" onClick={() => history("/")}>
                <img src={ArrowLeft} width={32} height={88} />
                <div className="d-flex flex-column">
                  {t("backTo")}
                  <span>{t("Selection")}</span>
                </div>
              </div>
            }
            <div className="pet_container__item">
              {userPet && !isLoading
                ? (
                  <div className="pet_container__single">
                    <div style={{ position: 'relative' }}>
                      <div
                        className="pet_container__single__parent"
                        onClick={onSelectFile}
                      >
                        {userPet.image
                          ? <img src={`data:image/jpeg;base64, ${userPet?.image}`} height="100%" />
                          : <img src={CatDog} height="95%" width="95%" />}
                      </div>
                      {!userPet.image &&
                        <div className={`pet_container__single__upload ${isMobile && "mobile"}`}>
                          {t("uploadPhoto")}
                        </div>}
                      <input
                        ref={ref}
                        onChange={onFileChange}
                        type="file"
                        hidden
                      />
                    </div>
                  </div>
                )
                : (
                  <Skeleton variant="rounded" width={isMobileOnly ? 138 : 242} height={isMobileOnly ? 174 : 312} sx={{ borderRadius: "20px" }} />
                )}
            </div>
            <div className="pet_main__info">
              {userPet
                ? (
                  <div className="hl2_white_big">
                    {userPet.data.nameOfPet}
                  </div>
                )
                : (
                  <div className="hl2_white_big">
                    <Skeleton variant="rounded" width={isMobileOnly ? 80 : 120} height={isMobileOnly ? 25 : 40} />
                  </div>
                )}
              <div className="gradient_bar"></div>
              <div className="pet_main__table">
                <div className="pet_main__table__item">
                  <div className="pet_main__table__item__left">
                    <div className="white_fat_rooney">
                      {t("birthDate")}:
                    </div>
                  </div>
                  <div className="pet_main__table__item__right">
                    <div className="white_slim_rooney">
                      {userPet
                        ? (formatAnyDate(userPet.data.dateOfBirth) || t("noInfo"))
                        : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                    </div>
                  </div>
                </div>
                <div className="pet_main__table__item">
                  <div className="pet_main__table__item__left">
                    <div className="white_fat_rooney">
                      {(userPet?.data?.hybridBreed1 &&
                        userPet?.data?.hybridBreed1 !== userPet?.data?.hybridBreed2)
                        ? t("firstBreed")
                        : t("breed")}:
                    </div>
                  </div>
                  <div className="pet_main__table__item__right">
                    <div className="white_slim_rooney">
                      {userPet
                        ? (t(userPet.data.hybridBreed1) || t(userPet.data.breed) || t("noInfo"))
                        : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                    </div>
                  </div>
                </div>
                {userPet?.data?.hybridBreed1 !== userPet?.data?.hybridBreed2 &&
                  userPet?.data?.hybridBreed2 &&
                  <div className="pet_main__table__item">
                    <div className="pet_main__table__item__left">
                      <div className="white_fat_rooney">
                        {t("secondBreed")}:
                      </div>
                    </div>
                    <div className="pet_main__table__item__right">
                      <div className="white_slim_rooney">
                        {userPet
                          ? (t(userPet.data.hybridBreed2) || t("noInfo"))
                          : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                      </div>
                    </div>
                  </div>}
                <div className="pet_main__table__item" style={{ margin: (!userPet || userPet?.data?.chipNumber) ? "" : "-14px 0 0 0" }}>
                  <div className="pet_main__table__item__left">
                    <div className="white_fat_rooney" style={{ margin: (!userPet || userPet?.data?.chipNumber) ? "" : "12px 0 0 0" }}>
                      {t("chipNumber")}:
                    </div>
                  </div>
                  <div className="pet_main__table__item__right">
                    <div className="white_slim_rooney" style={{ maxWidth: "unset" }}>
                      {userPet
                        ? userPet.data.chipNumber
                          ? userPet.data.chipNumber
                          : (petDataUpdating
                            ? <Loading noText fast scroll={false} size={isMobile ? 25 : 40} margin={isMobile ? "10px 0 0 0" : "5px 0 0 0"} />
                            : <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (!chipError && chipNumber.length === 15) updatePetChip();
                              }}
                            >
                              <div className="input-chip">
                                <div className="input-and-message">
                                  <div className="input-and-exclamation">
                                    <input
                                      type="number"
                                      placeholder={t("attentionNoChip")}
                                      value={chipNumber}
                                      onChange={handleChip}
                                      style={{ border: chipError ? "4px solid var(--b-color-main-2)" : "" }}
                                    />
                                    <CustomTooltip
                                      name={"noteReporOnlytWithChip"}
                                      sign="Exclamation"
                                      placement="bottom"
                                    />
                                  </div>
                                  <div className="input-message">
                                    {chipNumber.length === 15
                                      ? ""
                                      : !chipError
                                        ? t("pleaseEnterChip")
                                        : t("chipShould15")}
                                  </div>
                                </div>
                                <button
                                  type='submit'
                                  disabled={chipError || chipNumber.length === 0}
                                >
                                  OK
                                </button>
                              </div>
                            </form>)
                        : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                    </div>
                  </div>
                </div>
              </div>
              {userPet &&
                <div
                  className="button_red_common white-change"
                  // onClick={() => openWidgetSafely()}
                  onClick={() => setOpenModalPetData(true)}
                >
                  {t("changePetData")}
                </div>}
            </div>
          </div>

          {/* "WhatToDo" btn with Panda logo */}

          {userPet &&
            <div
              className={`insurances_container__toDoBtn relative ${isMobile && "mobile"}`}
              onClick={() => handleClick()}>
              <img src={PandaHeadBothHands} />
              <div
                ref={buttonRef}
                className={isMobileOnly ? "button_red_mobile" : "button_red_common"}
              >
                {t("whatToDo")}
              </div>
            </div>
          }
          {userPet
            ? (
              <div className="pet_product_header">
                <div className="headline1_red_title width100">
                  {userPet.data.nameOfPet}{t("petProduct")}
                </div>
                <div className="hl2_black_big">
                  {t("firstSight")}
                </div>
              </div>
            )
            : (
              <div className="pet_product_header">
                <Skeleton variant="rounded" width={isMobileOnly ? 200 : 300} height={isMobileOnly ? 60 : 100} />
              </div>
            )}
          <div className={`${isLeft
            ? "insurances_container__left_active"
            : "insurances_container__right_active"} ${isMobile && "mobile"} relative SlowShow`}>
            <div className="insurances_container__wrapper">
              {isLeft && (isMobile
                ? <>
                  <PawUI top="12vh" right="-13vw" rotation="left" width="40%" zIndex="-1" />
                  <PawUI top="44vh" left="-14vw" rotation="right" width="45%" zIndex="-1" />
                  <PawUI top="80vh" right="-8vw" rotation="left" width="45%" zIndex="-1" />
                  <PawUI top="130vh" left="-5vw" rotation="right" width="45%" zIndex="-1" />
                </>
                : <>
                  <PawUI top="60vh" right="-4vw" rotation="left" width="350px" zIndex="-1" />
                  <PawUI top="140vh" left="-7vw" rotation="right" width="400px" zIndex="-1" />
                  <PawUI top="240vh" right="-8vw" rotation="left" width="450px" zIndex="-1" />
                  <PawUI top="320vh" left="-5vw" rotation="right" width="350px" zIndex="-1" />

                </>)}
              <div
                className={`insurances_container__bookmark_active${isMobile ? "" : "__desktop"}`}
                style={isLeft ? { color: "#B83739" } : { color: "#979593" }}
              // onClick={() => { setIsLeft(!isLeft) }}
              >
                {userPet
                  ? (t(selectedPackage))
                  : (<Skeleton variant="rounded" height={isMobileOnly ? 30 : 80} />)}
              </div>
              {isLeft &&
                <>
                  {userPet
                    ?
                    <div className={`insurances_container__status_${!userPet.policy.terminationDate ? 'active' : 'inactive'} ${isMobile ? "mobile" : ""}`}>
                      {t(!userPet.policy.terminationDate ? 'active' : 'canceled')}
                    </div>
                    : (
                      isMobile
                        ? <Skeleton variant="rounded" width={80} height={35} sx={{ position: "relative", top: "0", right: "0" }} />
                        : <Skeleton variant="rounded" width={120} height={45} sx={{ position: "absolute", top: "30vh", right: "10vw" }} />
                    )}

                  {userPet
                    ?
                    <div onClick={() => { }} className="insurances_container__header">
                      {t(selectedPackage)}
                    </div>
                    :
                    <Skeleton
                      variant="rounded"
                      width={isMobileOnly ? 200 : 350}
                      height={isMobileOnly ? 25 : 45}
                      sx={{ margin: "5vh 0 0 0" }}
                    />
                  }
                  <div className="gap_centered">
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("coverage")}:</div>
                      {userPet
                        ?
                        <div className="gap_centered__item__right">
                          {`${(userPet.products.petInsurance.data.selectedCoverage) * 100}%`}
                        </div>
                        :
                        <div className="gap_centered__item__right">
                          <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                        </div>
                      }
                    </div>
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("commOfIns")}:</div>
                      {userPet
                        ?
                        <div className="gap_centered__item__right">
                          {formatAnyDate(userPet.policy.dateInception)}
                          {/* <CustomTooltip name={"tooltip4"} /> */}
                        </div>
                        :
                        <div className="gap_centered__item__right">
                          <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                        </div>
                      }
                    </div>
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("renewsOn")}:</div>
                      {userPet
                        ? (
                          <div className="gap_centered__item__right">
                            {formatAnyDate(userPet.policy.dateExpiry)}
                            {/* <CustomTooltip name={`${t("tooltip5")} ${getDateGE(formatAnyDate(userPet.policy.dateExpiry.split("T")[0]), true)}`} /> */}
                          </div>
                        )
                        : (
                          <div className="gap_centered__item__right">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                    </div>
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("customerNumber")}:</div>
                      {userPet
                        ? (<div className="gap_centered__item__right">{userInfo.pandaId || t("noInfo")}</div>)
                        : (
                          <div className="gap_centered__item__right">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                    </div>
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("insNumber")}:</div>
                      {userPet
                        ? (<div className="gap_centered__item__right">{userPet.policy.policyNo || t("noInfo")}</div>)
                        : (
                          <div className="gap_centered__item__right">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                    </div>
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("insPremium")}:</div>
                      {userPet
                        ? (
                          <div className="gap_centered__item__right">
                            {(userPet.monthlyFee || userPet.monthlyFee === 0)
                              ? `${userPet.monthlyFee} €/${t(paymentFrequency)}`
                              : t("noInfo")}
                          </div>
                        )
                        : (
                          <div className="gap_centered__item__right">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                    </div>

                    {/* Payment method  */}

                    <div className="gap_centered__item" style={{ margin: "20px 0 0 0" }}>
                      <div className="gap_centered__item__left">{t("paymentMethod")}:</div>
                      {userPet
                        ? (<div className="gap_centered__item__right">{userInfo?.brand ? String(userInfo?.brand).toUpperCase() : t('noInfo')}</div>)
                        : (
                          <div className="gap_centered__item__right">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                    </div>
                    <div className="gap_centered__item">
                      <div className="gap_centered__item__left">{t("cardNumber")}:</div>
                      {userPet
                        ? (<div className="gap_centered__item__right relative">
                          {userInfo?.card4
                            ? `**** ${userInfo.card4}`
                            : t("noInfo")}
                        </div>)
                        : (
                          <div className="gap_centered__item__right">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                    </div>
                  </div>


                  {/* Docs section */}

                  {userPet
                    ? (
                      <div className="tariff_details">
                        {hasSubscription &&
                          <div
                            className="button_red_common wide-change"
                            onClick={() => setOpenModalStripe(true)}
                          >
                            {t("changePayment")}
                          </div>
                        }
                        <div className="tariff_details__title">{t("downloadDoc")}</div>
                        <div className="tariff_details__wrapper">
                          {docHistory?.map((el, policyInd) => (
                            <div key={policyInd} className="tariff_details__wrapper">
                              <div
                                className="tariff_details__item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  expandDocumentHandler(policyInd);
                                }}
                                style={{
                                  transition: "0.2s ease-in"
                                }}
                              >
                                <div className="tariff_details__item__left">
                                  {(isDownloading && downloadingVersion === el.version)
                                    ? <Loading noText fast scroll={false} size={isMobile ? 20 : 40} />
                                    : <img src={RedDownload} width={40} height={40}
                                      className="square20"
                                    // temporary hidden option to download docs in archive

                                    // className="square20 pointer"
                                    // onClick={(e) => {
                                    //   e.stopPropagation();
                                    //   setDownloadingVersion(el.version);
                                    //   downloadDocs(el.version.split("/")[1]);
                                    // }}
                                    />}
                                </div>
                                <div className="tariff_details__item__right">
                                  <div className="tariff_details__item__right__header">
                                    {docHistory.length > 1 ? `${t("yourDocuments")} ${el.version}` : t("yourDocuments")}
                                    <img
                                      src={ArrowUp}
                                      alt="Arrow"
                                      style={{
                                        transform: (el?.expanded === true) ? "" : "rotate(180deg)",
                                        transition: "0.3s"
                                      }}
                                    />
                                  </div>
                                  {(el?.expanded === true) && !isMobile &&
                                    <DocsDetails
                                      docList={el.documents}
                                      policyInd={policyInd}
                                      downloadDocumentHandler={downloadDocumentHandler}
                                      downloadingDocInd={downloadingDocInd}
                                      downloadingPolicyInd={downloadingPolicyInd}
                                    />}
                                </div>
                              </div>
                              {(el?.expanded === true) && isMobile &&
                                <DocsDetails
                                  docList={el.documents}
                                  policyInd={policyInd}
                                  downloadDocumentHandler={downloadDocumentHandler}
                                  downloadingDocInd={downloadingDocInd}
                                  downloadingPolicyInd={downloadingPolicyInd}
                                />}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                    : (
                      <div className="documents_download">
                        <div className="documents_download__title">{t("downloadDoc")}</div>
                        <Skeleton variant="rounded" width={isMobileOnly ? 300 : 500} height={isMobileOnly ? 30 : 60} />
                      </div>
                    )}

                  {/* Tariff`s cover */}

                  {userPet
                    ? (
                      <div className="tariff_cover">
                        <Markup className="tariff_cover__title" content={t("whatCovered")} />
                        <div className="tariff_cover__table">
                          <img src={PandaHead} width={180} height={100} />

                          <div className="tariff_cover__item">
                            <div className="tariff_cover__left">{t("compensations")}</div>
                            <div className="tariff_cover__right_container">
                              <div className="tariff_cover__right">
                                <div className="tariff_cover__right__details">
                                  {userPet?.products?.petInsurance?.data?.selectedCoverage &&
                                    <div
                                      className="tariff_cover__right__details__title"
                                      style={{ fontSize: isMobile ? "14px" : "21px" }}
                                    >
                                      {COVERAGES[userPet?.products?.petInsurance?.data?.selectedCoverage][0]}
                                    </div>}
                                </div>
                                {userPet?.products?.petInsurance?.data?.selectedCoverage &&
                                  <CustomTooltip name={t(COVERAGES[userPet?.products?.petInsurance?.data?.selectedCoverage][1])} />}
                              </div>
                            </div>
                          </div>
                          {chartsExcel[petType].hasOwnProperty(selectedPackage) &&
                            chartsExcel[petType][selectedPackage].tarif_info.map((el, ind) => (
                              <div className="tariff_cover__item" key={`tar-item-${ind}`}>
                                <div className="tariff_cover__left">{t(el.itemName)}</div>
                                <div className="tariff_cover__right_container">
                                  {el.children.length > 1
                                    ? el.children.map((child, ind) => (
                                      <div className="tariff_cover__right_few" key={ind}>
                                        <div className="tariff_cover__right__details">
                                          {child.title &&
                                            <div className="tariff_cover__right__details__title">
                                              {t(child.title)}
                                            </div>}
                                          {child.content &&
                                            <div className="tariff_cover__right__details__content">
                                              {t(child.content)}
                                            </div>}
                                          {child.additional &&
                                            <div className="tariff_cover__right__details__title">
                                              {t(child.additional)}
                                            </div>}
                                        </div>
                                        {child.tooltip &&
                                          <CustomTooltip name={child.tooltip} />}
                                      </div>
                                    ))
                                    : <div className="tariff_cover__right">
                                      <div className="tariff_cover__right__details">
                                        {el.children[0].title &&
                                          <div className="tariff_cover__right__details__title"
                                            style={{ fontSize: isMobile ? "14px" : "21px" }}
                                          >
                                            {t(el.children[0].title)}
                                          </div>}
                                        {el.children[0].content &&
                                          <div className="tariff_cover__right__details__content">
                                            {t(el.children[0].content)}
                                          </div>}
                                        {el.children[0].additional &&
                                          <div className="tariff_cover__right__details__title">
                                            {t(el.children[0].additional)}
                                          </div>}
                                      </div>
                                      {el.children[0].tooltip &&
                                        <CustomTooltip
                                          name={el.children[0].tooltip}
                                          scrollable={el.children[0].scrollable}
                                        />}
                                    </div>
                                  }
                                </div>
                              </div>

                            ))}
                        </div>
                        <div
                          className="button_red_common wide-change"
                          style={{ margin: "40px 0 0 0" }}
                          onClick={() => openWidgetSafely()}
                        >
                          {t("changeContract")}
                        </div>
                      </div>
                    )
                    : (
                      <div className="tariff_cover">
                        <Markup className="tariff_cover__title" content={t("whatCovered")} />
                        <Skeleton variant="rounded" width={isMobile ? "100%" : 620} height={isMobileOnly ? 300 : 500} sx={{ margin: "20px 0 20px 0" }} />
                      </div>
                    )}

                  {(userPet && chartsExcel[petType].hasOwnProperty(selectedPackage))
                    ? (
                      <div className="tariff_details">
                        <div className="tariff_details__title">{t("tariffInfo")}</div>
                        <div className="tariff_details__wrapper">
                          {showSpecialWaiting &&
                            <>
                              <div className="tariff_details__item">
                                <div className="tariff_details__item__left">
                                  <img src={RedCheck} width={34} height={26} />
                                </div>
                                <div className="tariff_details__item__right">
                                  <div className="tariff_details__item__right__header" onClick={() => setIsCheckOpen3(!isCheckOpen3)}>
                                    {t("specialWaitingPreiod_3")}
                                    <img
                                      src={ArrowUp}
                                      style={{
                                        transform: (isCheckOpen3) ? "" : "rotate(180deg)",
                                        transition: "0.3s"
                                      }}
                                    />
                                  </div>
                                  {isCheckOpen3 && !isMobile &&
                                    <CheckDetails
                                      tariff={chartsExcel[petType]}
                                      selectedPackage={selectedPackage}
                                      available="available3"
                                    />}
                                </div>
                              </div>
                              {isCheckOpen3 && isMobile &&
                                <CheckDetails
                                  tariff={chartsExcel[petType]}
                                  selectedPackage={selectedPackage}
                                  available="available3"
                                />}
                              <div className="tariff_details__item">
                                <div className="tariff_details__item__left">
                                  <img src={RedCheck} width={34} height={26} />
                                </div>
                                <div className="tariff_details__item__right">
                                  <div className="tariff_details__item__right__header" onClick={() => setIsCheckOpen(!isCheckOpen12)}>
                                    {t("specialWaitingPreiod_12")}
                                    <img
                                      src={ArrowUp}
                                      style={{
                                        transform: (isCheckOpen12) ? "" : "rotate(180deg)",
                                        transition: "0.3s"
                                      }}
                                    />
                                  </div>
                                  {isCheckOpen12 && !isMobile &&
                                    <CheckDetails
                                      tariff={chartsExcel[petType]}
                                      selectedPackage={selectedPackage}
                                      available="available12"
                                    />}
                                </div>
                              </div>
                              {isCheckOpen12 && isMobile &&
                                <CheckDetails
                                  tariff={chartsExcel[petType]}
                                  selectedPackage={selectedPackage}
                                  available="available12"
                                />}
                              <div className="tariff_details__item">
                                <div className="tariff_details__item__left">
                                  <img src={RedCheck} width={34} height={26} />
                                </div>
                                <div className="tariff_details__item__right">
                                  <div className="tariff_details__item__right__header" onClick={() => setIsCheckOpen18(!isCheckOpen18)}>
                                    {t("specialWaitingPreiod_18")}
                                    <img
                                      src={ArrowUp}
                                      style={{
                                        transform: (isCheckOpen18) ? "" : "rotate(180deg)",
                                        transition: "0.3s"
                                      }}
                                    />
                                  </div>
                                  {isCheckOpen18 && !isMobile &&
                                    <CheckDetails
                                      tariff={chartsExcel[petType]}
                                      selectedPackage={selectedPackage}
                                      available="available18"
                                    />}
                                </div>
                              </div>
                              {isCheckOpen18 && isMobile &&
                                <CheckDetails
                                  tariff={chartsExcel[petType]}
                                  selectedPackage={selectedPackage}
                                  available="available18"
                                />}
                            </>}
                          <div className="tariff_details__item">
                            <div className="tariff_details__item__left">
                              <img src={BlackCross} width={23} height={27} />
                            </div>
                            <div className="tariff_details__item__right">
                              <div className="tariff_details__item__right__header" onClick={() => setIsCrossOpen(!isCrossOpen)}>
                                {t("notInsured")}
                                <img
                                  src={ArrowUp}
                                  style={{
                                    transform: (isCrossOpen) ? "" : "rotate(180deg)",
                                    transition: "0.3s"
                                  }}
                                />
                              </div>
                              {isCrossOpen && !isMobile &&
                                <CrossDetails
                                  tariff={chartsExcel[petType]}
                                  selectedPackage={selectedPackage}
                                />}
                            </div>
                          </div>
                          {isCrossOpen && isMobile &&
                            <CrossDetails
                              tariff={chartsExcel[petType]}
                              selectedPackage={selectedPackage}
                            />}
                        </div>
                      </div>
                    )
                    : (
                      <div className="tariff_details">
                        <div className="tariff_details__title">{t("tariffInfo")}</div>
                        <Skeleton variant="rounded" width={isMobile ? "100%" : 620} height={isMobile ? 40 : 70} sx={isMobile ? { margin: "0" } : { margin: "20px 0 0 0" }} />
                        <Skeleton variant="rounded" width={isMobile ? "100%" : 620} height={isMobile ? 40 : 70} sx={isMobile ? { margin: "5px 0 0 0" } : { margin: "20px 0 0 0" }} />
                      </div>
                    )}
                </>}
              {!isLeft && (
                <div>
                  Some info
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile
          ? <>
            <PawUI top="-28vh" right="9vw" rotation="right" width="37%" zIndex="-1" />
            <PawUI top="42vh" left="0" rotation="right" width="42%" zIndex="-1" />
          </>
          : <>
            <PawUI top="3vh" right="1vw" rotation="right" width="215px" zIndex="-1" />
            <PawUI top="82vh" left="-5vw" rotation="right" width="350px" zIndex="-1" />
          </>}
      </div>
      <Footer />
      {openModalStripe && (
        <ModalStripe
          openModalStripe={openModalStripe}
          setOpenModalStripe={setOpenModalStripe}
          stripePromise={stripePromise}
          quoteId={quoteId}
          policyId={id}
          insuredItemId={insuredItemId}
        />
      )}
      {openModalPetData && (
        <ModalPetData
          openModalPetData={openModalPetData}
          setOpenModalPetData={setOpenModalPetData}
          initialPetType={petType}
          petData={userPet}
        />
      )}
      {error && (
        <ErrorModal
          openModal={error}
          setErrorModal={() => setError(!error)}
        />
      )}
    </div>
  );
}
