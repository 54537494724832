import * as React from "react";
import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { updateWtdModalOpen } from "../../../config/actions";
import { useState } from "react";
import { showToast } from "../../../config/helpers";
import { getToken } from "../../../config/token";
import Loading from "../../../components/common/Loading";
import { Request } from "../../../config/requests";

function ModalRequireChip({
  openModalRequireChip,
  setOpenModalRequireChip,
  petId,
  setOpenModalWhatToDo,
  fetchData,
  getPetInfo
}) {
  const { t } = useTranslation();
  const token = getToken();
  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.form.user);
  const [chipNumber, setChipNumber] = useState("");
  const [chipError, setChipError] = useState(false);
  const [petDataUpdating, setPetDataUpdating] = useState(false);

  function isValidNumber(str) {
    return /^[0-9]{15}$/.test(str);
  }

  const handleChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setChipNumber(value);
        setChipError(false);
      } else {
        setChipNumber(value);
        setChipError(true);
      }
    }
  };

  const updatePetChip = () => {
    setPetDataUpdating(true);
    Request({
      method: "put",
      token: token,
      route: `api/pet/${petId}`,
      values: {
        chipNumber: chipNumber,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        if (json.status === "success") {
          fetchData();
          if (getPetInfo) getPetInfo();
        }
        setOpenModalRequireChip(false);
        showToast(t("dataChangesConfirmed"));
      })
      .catch(() => {
        showToast(t("somethingWentWrong"), { error: true });
      })
      .finally(() => {
        setPetDataUpdating(false);
      });
  };

  const askChipEmail = () => {
    Request({
      method: "post",
      token: token,
      route: `api/pet/send_email?email=${userInfo.email}`,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
      })
      .catch(() => {
        showToast(t("somethingWentWrong"), { error: true });
      })
  };

  function handleClose() {
    dispatch(updateWtdModalOpen(false));
    askChipEmail();
    setOpenModalWhatToDo(false);
    setOpenModalRequireChip(false);
  }


  return (
    <ModalWrapper
      headerTitle={t("missingChip")}
      openModal={openModalRequireChip}
      setModalWrapper={() => handleClose()}
    >
      <div className="modal_container__body__text">
        {t("enterChipBefore")}
      </div>

      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          if (!chipError && chipNumber.length === 15) updatePetChip();
        }}
      >
        <div className="modal_container__body__inputs">
          <input
            className="modal_container__body__input"
            placeholder={t("chipHere")}
            value={chipNumber}
            onChange={handleChip}
            style={{
              border: chipError ? "4px solid var(--b-color-main-2)" : "",
            }}
          />
          {chipError &&
            <div className="modal_container__body__error FastShow">
              {t("chipWrong")}
            </div>}
          {petDataUpdating
            ? <Loading noText fast scroll={false} size={isMobile ? 30 : 40} />
            : <button
              className={`button_red_small ${isMobileOnly && "mobile"}`}
              style={{ margin: "15px 0 0 0" }}
              type='submit'
              disabled={chipError || chipNumber.length === 0}
            >
              OK
            </button>}
        </div>

      </form>
    </ModalWrapper >
  );
}

export default ModalRequireChip;